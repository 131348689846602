import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import { Slide } from 'react-awesome-reveal';
import { Color } from '../_shared/enums/color';
import PageLayout, { PageLayoutBackground } from '../components/common/page-layout/page-layout';
import Button, { ButtonThemeColor, ButtonThemeType } from '../components/templates/button/button';
import HeadlineCopytext from '../components/templates/headline-copytext/headline-copytext';
import ResponsiveImage from '../components/templates/responsive-image/responsive-image';
import SectionIntended from '../components/templates/section-intended/section-intended';
import AssetsDataContext from '../context/assets-data.context';

import IndexStyles from './_scss/index.module.scss';

const IndexPage = () => {
    const { assetsData } = React.useContext(AssetsDataContext);
    const [scrolled, setScrolled] = React.useState(false);
    const intl = useIntl();

    const checkScrollPosition = () => {
        if (window.scrollY > 10) {
            setScrolled(true);
            window.removeEventListener('scroll', checkScrollPosition);
        }
    };

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', checkScrollPosition);

            return () => {
                window.removeEventListener('scroll', checkScrollPosition);
            };
        }
    }, []);

    return (
        <PageLayout
            pageBackground={PageLayoutBackground.Animated}
            title={intl.formatMessage({ id: 'pages.index.title' })}
            description={intl.formatMessage({ id: 'pages.index.description' })}
        >
            {assetsData.images.edges.length > 0 && (
                <>
                    {/*01 START*/}
                    <div className={IndexStyles.section01}>
                        <SectionIntended>
                            <Slide direction="left" triggerOnce={true} delay={1000}>
                                <HeadlineCopytext
                                    color={Color.White}
                                    alignment="center"
                                    headline={[
                                        {
                                            style: 'h1',
                                            text: intl.formatMessage({ id: 'pages.index.sections.start.headline' }),
                                        },
                                    ]}
                                    copytext={[
                                        {
                                            text: intl.formatMessage({ id: 'pages.index.sections.start.copytext' }),
                                        },
                                    ]}
                                />
                            </Slide>
                        </SectionIntended>
                    </div>
                    {/*01 END*/}
                    {/*02 START*/}
                    <div className={IndexStyles.section02}>
                        <ResponsiveImage
                            images={[
                                { path: '_assets/pages/home/02/background-small.png', media: '(max-width: 767px)' },
                                { path: '_assets/pages/home/02/background-large.png', media: '(min-width: 786px)' },
                            ]}
                            altText=""
                        />
                    </div>
                    {/*02 END*/}
                    {/*03 START*/}
                    <div className={[IndexStyles.section03, 'gradient-background-static'].join(' ')}>
                        {/*LEFT START*/}
                        <div className={IndexStyles.section03Left}>
                            <ResponsiveImage
                                images={[
                                    { path: '_assets/pages/home/03/cycle-small.png', media: '(max-width: 1024px)' },
                                    { path: '_assets/pages/home/03/cycle.png', media: '(min-width: 1025px)' },
                                ]}
                                altText=""
                            />

                            <div className={IndexStyles.section03LeftContent}>
                                <Slide direction="left" triggerOnce={true} delay={0} fraction={0}>
                                    <HeadlineCopytext
                                        color={Color.White}
                                        alignment="left"
                                        headline={[
                                            {
                                                style: 'h3',
                                                text: intl.formatMessage({
                                                    id: 'pages.index.sections.cycle.headlines.0',
                                                }),
                                                color: Color.Rose,
                                            },
                                            {
                                                style: 'h4',
                                                text: intl.formatMessage({
                                                    id: 'pages.index.sections.cycle.headlines.1',
                                                }),
                                                color: Color.Rose,
                                            },
                                        ]}
                                        copytext={[
                                            {
                                                text: intl.formatMessage({ id: 'pages.index.sections.cycle.copytext' }),
                                            },
                                        ]}
                                    >
                                        <Button
                                            type="link"
                                            to="/cycle"
                                            theme={{
                                                type: ButtonThemeType.OutlineWhite,
                                                color: ButtonThemeColor.BlueRose,
                                            }}
                                        >
                                            <FormattedMessage id="pages.index.sections.cycle.button" />
                                        </Button>
                                    </HeadlineCopytext>
                                </Slide>
                            </div>
                        </div>
                        {/*LEFT END*/}
                        {/*RIGHT START*/}
                        <div className={IndexStyles.section03Right}>
                            <ResponsiveImage
                                images={[
                                    { path: '_assets/pages/home/03/mirror-small.png', media: '(max-width: 767px)' },
                                    { path: '_assets/pages/home/03/mirror.png', media: '(min-width: 1025px)' },
                                ]}
                                altText=""
                            />

                            <div className={IndexStyles.section03RightContent}>
                                <Slide direction="right" triggerOnce={true} delay={0} fraction={0.5}>
                                    <HeadlineCopytext
                                        color={Color.White}
                                        alignment="left"
                                        headline={[
                                            {
                                                style: 'h3',
                                                text: intl.formatMessage({
                                                    id: 'pages.index.sections.mirror.headlines.0',
                                                }),
                                                color: Color.Blue,
                                            },
                                            {
                                                style: 'h4',
                                                text: intl.formatMessage({
                                                    id: 'pages.index.sections.mirror.headlines.1',
                                                }),
                                                color: Color.Blue,
                                            },
                                        ]}
                                        copytext={[
                                            {
                                                text: intl.formatMessage({
                                                    id: 'pages.index.sections.mirror.copytext',
                                                }),
                                            },
                                        ]}
                                    >
                                        <Button
                                            type="link"
                                            to="/mirror"
                                            theme={{
                                                type: ButtonThemeType.OutlineWhite,
                                                color: ButtonThemeColor.BlueRose,
                                            }}
                                        >
                                            <FormattedMessage id="pages.index.sections.mirror.button" />
                                        </Button>
                                    </HeadlineCopytext>
                                </Slide>
                            </div>
                        </div>
                        {/*RIGHT END*/}
                    </div>
                    {/*03 END*/}

                    {/*ARROWS START*/}
                    {!scrolled && <div className={IndexStyles.arrows} />}
                    {/*ARROWS END*/}
                </>
            )}
        </PageLayout>
    );
};

export default IndexPage;
